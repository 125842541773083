<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradientdd" x1="0.721" x2="0.266" y2="1.109">
     <stop offset="0" stop-color="#45b7ff"/>
     <stop offset="1" stop-color="#2736a5"/>
    </linearGradient>
   </defs>
   <g id="LinkedIn" transform="translate(-290 -208)">
    <rect :fill="background" data-name="Rectangle 76" height="60" id="Rectangle_76" transform="translate(290 208)" width="60"/>
    <path d="M6.715,30H.5V9.971h6.22ZM3.6,7.239A3.619,3.619,0,1,1,7.2,3.6,3.632,3.632,0,0,1,3.6,7.239ZM29.993,30H23.787V20.25c0-2.324-.047-5.3-3.234-5.3-3.234,0-3.729,2.525-3.729,5.136V30H10.611V9.971h5.965V12.7h.087a6.535,6.535,0,0,1,5.885-3.234C28.843,9.469,30,13.614,30,19V30Z" data-name="Icon awesome-linkedin-in" id="Icon_awesome-linkedin-in" style="fill: url(#linear-gradientdd);" transform="translate(305 222.999)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>